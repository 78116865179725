import { useEffect, useRef, useState } from 'react'
import { Box, Container, Heading, Flex, Square, Image, Text, SimpleGrid, Skeleton } from '@chakra-ui/react'
import notfound from '../../assets/images/notfound.png'
import * as api from '../../services/api_service'
import { useLocation } from 'react-router-dom';
import OpenPositionCard from '../../components/OpenPositionCard';
import ClosePositionCard from '../../components/ClosePositionCard';
import UseAuth from '../../hooks/UseAuth'

function Orders() {

  const { orders, setOrders } = UseAuth();

  const tTypeSearch = useLocation().search;
  const tTypeSearchName = new URLSearchParams(tTypeSearch).get('to') || 'opened';
  const tTypeName = tTypeSearchName.toLowerCase();
  const [tab, setTab] = useState(tTypeName);
  const _timer = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  async function getOrders() {
    try {
      const resp = await api.trades();
      setOrders(resp.data.data);
      console.log('order Fetched');
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOrders()

    _timer.current = setInterval(() => {
      getOrders();
    }, (10 * 1000));

    return () => {
      clearInterval(_timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container maxW={'container.lg'} pb={5}>
        <Box h={'auto'} mt={{ base: 5, md: 10 }}>
          <Flex justifyContent={'space-between'} mb={5}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Positions</Heading>
            <Flex>
              <Box mr={5}>
                <Box borderBottom={tab === 'opened' ? 'solid 2px #fff' : 'none'} color={tab === 'opened' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('opened')}>Opened</Box>
              </Box>
              <Box mr={2}>
                <Box borderBottom={tab === 'closed' ? 'solid 2px #fff' : 'none'} color={tab === 'closed' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('closed')}>Closed</Box>
              </Box>
            </Flex>
          </Flex>

          {
            tab === 'opened' ?
              orders.length > 0 ?
                orders.filter((e) => e.status === 1).length > 0 ?
                  //for opened
                  <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>

                    {
                      orders.filter((e) => e.status === 1).map((e, k) => (
                        <OpenPositionCard key={k} order={e} setOrders={setOrders} setTab={setTab} />
                      ))
                    }

                  </SimpleGrid>
                  :
                  <Square h={'500px'} mt={{ base: '0px', md: '100px' }}>
                    <Box>
                      <Text textAlign={'center'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>No record found !</Text><br />
                      <Image src={notfound} w={{ base: '300px', md: '500px' }} />
                    </Box>
                  </Square>
                :
                <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>
                  {Array.from(Array(6)).map((e, k) => (
                    <Skeleton key={k} height={200} startColor='rgba(100,100,100,0.2)' endColor='rgba(0,0,0,0.2)' isLoaded={orders.length > 0} />
                  ))}
                </SimpleGrid>

              :

              //for closed

              orders.length > 0 ?
                orders.filter((e) => e.status !== 1).length > 0 ?
                  //for opened
                  <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>

                    {
                      orders.filter((e) => e.status !== 1).map((e, k) => (
                        <ClosePositionCard order={e} key={k} />
                      ))
                    }

                  </SimpleGrid>
                  :
                  <Square h={'500px'} mt={{ base: '0px', md: '100px' }}>
                    <Box>
                      <Text textAlign={'center'} pb={3} borderBottom={'solid 1px rgba(230, 230, 230, 0.2)'}>No record found !</Text><br />
                      <Image src={notfound} w={{ base: '300px', md: '500px' }} />
                    </Box>
                  </Square>
                :
                <SimpleGrid columns={{ base: 1, md: 3 }} gap={8}>
                  {Array.from(Array(6)).map((e, k) => (
                    <Skeleton key={k} height={200} isLoaded={orders.length > 0} />
                  ))}
                </SimpleGrid>
          }
        </Box>
        <Box h={'100px'}></Box>
      </Container>
    </>
  )
}

export default Orders