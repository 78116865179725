import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { Box } from '@chakra-ui/react'
import NavHeader from '../components/NavHeader'
import useAuth from '../hooks/UseAuth'
import Loader from '../components/Loader'


function UserLayout() {

    const { user, isAuthenticated, isAuthenticating, emailVerifiedMiddleware, authMiddleware, token } = useAuth()

    const { pathname } = useLocation();

    useEffect(() => {
        authMiddleware()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, token, isAuthenticating])

    useEffect(() => {
        emailVerifiedMiddleware()
    }, [user, emailVerifiedMiddleware])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <ToastContainer theme={'dark'} />
            {!isAuthenticated || isAuthenticating ? <Loader /> :
                <Box bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} w={'100%'} minH={'100vh'}>

                    <NavHeader />

                    <Box pt={'80px'}>
                        <Outlet />
                    </Box>

                </Box>
            }
        </>
    )
}

export default UserLayout