
import React, { useState } from 'react'
import { Box, Button, Card, CardBody, Flex, FormControl, FormErrorMessage, Input, InputGroup, InputRightAddon, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import style from '../assets/modules/tradercard.module.css'
import * as api from '../services/api_service'
import { toast } from 'react-toastify';
import { kCommissionRate, toastConfig } from '../utils/global';
import UseAuth from '../hooks/UseAuth';
import { useNavigate } from 'react-router-dom';
import { currencyFormat } from '../utils/helper';
import SymbolSearch from './SymbolSearch';

function TradeCard({ symbol, symbol24HrTicker, activeSymbolBuyPrice, maxBuyPrice, symbols, toggleWatchlist, watchlist }) {

  const [activeExecutionType, setActiveExecutionType] = useState('market execution');
  const [isFetching, setIsFetching] = useState(false)
  const [mAmount, setMAmount] = useState('');
  const [lEntry, setLEntry] = useState('');
  const [lTp, setLTp] = useState('');
  const [lSl, setLSl] = useState('');
  const [lAmount, setLAmount] = useState('');

  const { user, setUser, setOrders } = UseAuth();
  const navigate = useNavigate()


  async function handleMTrade(direction) {
    const mPayload = {
      entry: symbol24HrTicker ? parseFloat(symbol24HrTicker.close) : '',
      amount: activeSymbolBuyPrice ? mAmount * parseFloat(activeSymbolBuyPrice) : '',
      buy_amount: mAmount,
      sell_amount: getCurrentTotal('market execution') <= 0 ? '' : `${getCurrentTotal('market execution')}`,
      symbol: symbol ? symbol.id : '',
      order_type: activeExecutionType,
      direction: direction,
    }
    setIsFetching(true)

    try {
      const resp = await api.trade(mPayload)
      if (user?.demo) {
        setUser((user_) => {
          return { ...user_, demo_balance: user_.demo_balance - parseFloat(resp.data.data.amount) }
        })
      } else {
        setUser((user_) => {
          return { ...user_, balance: user_.balance - parseFloat(resp.data.data.amount) }
        })
      }
      setOrders((e) => ([resp.data.data, ...e]))
      setIsFetching(false)
      toast.success("Trade Placed Successfully", toastConfig)
      navigate('/user/orders')
    } catch (error) {
      setIsFetching(false)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  async function handleLTrade(direction) {
    const mPayload = {
      entry: lEntry,
      sl: lSl,
      tp: lTp,
      amount: activeSymbolBuyPrice ? lAmount * parseFloat(activeSymbolBuyPrice) : '',
      buy_amount: lAmount,
      sell_amount: getCurrentTotal('limit order') <= 0 ? '' : `${getCurrentTotal('limit order')}`,
      symbol: symbol ? symbol.id : '',
      order_type: activeExecutionType,
      direction: direction,
    }
    setIsFetching(true)

    try {
      const resp = await api.trade(mPayload)
      if (user?.demo) {
        setUser((user_) => {
          return { ...user_, demo_balance: user_.demo_balance - parseFloat(resp.data.data.amount) }
        })
      } else {
        setUser((user_) => {
          return { ...user_, balance: user_.balance - parseFloat(resp.data.data.amount) }
        })
      }
      setIsFetching(false)
      toast.success("Trade Placed Successfully", toastConfig)
      navigate('/user/orders')
    } catch (error) {
      setIsFetching(false)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  function isLDisabled() {
    return !activeSymbolBuyPrice || !symbol24HrTicker || !isValidAmount('limit order') || symbol === null || lEntry === '' || lEntry <= 0 || lTp === '' || lSl === '' || lAmount === '' || parseFloat(lAmount) <= 0 || parseFloat(lTp) <= 0 || parseFloat(lSl) <= 0 || isFetching
  }

  function isMDisabled() {
    return !activeSymbolBuyPrice || !symbol24HrTicker || !isValidAmount('market execution') || symbol === null || mAmount === '' || parseFloat(mAmount) <= 0 || isFetching
  }

  function getCurrentBalance() {
    return (user.demo === 1 ? user.demo_balance : user.balance) * (1 - kCommissionRate)
  }

  function getCurrentTotal(type) {
    return symbol24HrTicker ? parseFloat(symbol24HrTicker.close) * (type === 'market execution' ? mAmount : lAmount) : 0
  }

  function isValidAmount(type) {
    return maxBuyPrice && maxBuyPrice >= (type === 'market execution' ? mAmount : lAmount);
  }

  return (
    <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'}>
      <CardBody>
        <Box>
         { symbol24HrTicker !== null && <SymbolSearch symbol24HrTicker={symbol24HrTicker} symbols={symbols} toggleWatchlist={toggleWatchlist} watchlist={watchlist} width={'100%'} />}
          <SimpleGrid columns={2} mb={4}>
            <button className={activeExecutionType === "market execution" ? style.activeexecutiontype : style.inactiveexecutiontype} onClick={() => { setActiveExecutionType("market execution"); }}>Market</button>
            <button className={activeExecutionType === "limit order" ? style.activeexecutiontype : style.inactiveexecutiontype} onClick={() => { setActiveExecutionType("limit order"); }}>Limit</button>
          </SimpleGrid>

          {
            activeExecutionType === "market execution" ?

              // market Box
              <Box>

                <Box mt={6} mb={3}>
                  <FormControl>
                    <Text mb={3}>Price</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' value={symbol24HrTicker ? parseFloat(symbol24HrTicker.close) : ''} bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={'0.00'} disabled />
                      <InputRightAddon children={`@Market ${symbol ? symbol.sell : '...'}`} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <Box mt={6} mb={4}>
                  <FormControl isInvalid={!isValidAmount('market execution')}>
                    <Flex justifyContent={'space-between'}>
                      <Text mb={3}>Amount</Text>
                      <Flex gap={2}>
                        <Text>Max: </Text>
                        {
                          activeSymbolBuyPrice && symbol24HrTicker ?
                            <Box textAlign={'right'} color={(isValidAmount('market execution') ? 'green.400' : 'red.400')}>
                              <Text fontSize={'12px'}>{(maxBuyPrice || 0).toFixed(4)} {symbol ? symbol.buy : '...'}</Text>
                              <Text fontSize={'10px'}>{currencyFormat(getCurrentBalance())}</Text>
                            </Box> :
                            <Spinner size={'md'} />
                        }
                      </Flex>
                    </Flex>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' value={mAmount} bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} onChange={(e) => setMAmount(e.target.value)} placeholder={'0.00'} />
                      <InputRightAddon children={symbol ? symbol.buy : '...'} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                    <FormErrorMessage>
                      <Text fontSize={'smaller'} >
                        {
                          symbol && activeSymbolBuyPrice ?
                            <>Insufficient balance. {mAmount} {symbol.buy} = {currencyFormat(activeSymbolBuyPrice * mAmount)}</> :
                            ''
                        }
                      </Text>
                    </FormErrorMessage>
                    {activeSymbolBuyPrice && isValidAmount('market execution') && mAmount !== '' ? <Text fontSize={'12px'} color={'yellow.500'}>USD: -{currencyFormat(mAmount * parseFloat(activeSymbolBuyPrice))}</Text> : ''}
                  </FormControl>
                </Box>

                <hr style={{ borderColor: 'rgba(255,255,255,0.3)' }} />

                <Box mt={3} fontSize={'14px'} mb={4}>

                  <Flex justifyContent={'space-between'} mb={1}>
                    <Flex>
                      <Text>Total</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>({symbol ? symbol.sell : '...'})</Text>
                    </Flex>
                    <Text>{getCurrentTotal('market execution') <= 0 ? '0.0000000000' : `${getCurrentTotal('market execution')}`}</Text>
                  </Flex>

                  <Flex justifyContent={'space-between'} mb={1} fontSize={'xs'}>
                    <Flex>
                      <Text>Commission</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>(0.30%)</Text>
                    </Flex>
                    <Text>{getCurrentTotal('market execution') <= 0 ? '0.0000000000' : `${(getCurrentTotal('market execution') * kCommissionRate).toFixed(5)}`}</Text>
                  </Flex>

                </Box>

                <SimpleGrid columns={2} gap={2} mb={3}>
                  <Button colorScheme={'green'} onClick={() => handleMTrade('buy')} isDisabled={isMDisabled()}>Buy</Button>
                  <Button colorScheme={'red'} onClick={() => handleMTrade('sell')} isDisabled={isMDisabled()}>Sell</Button>
                </SimpleGrid>
              </Box> :



              // Limit Box


              <Box>

                <Box mt={6} mb={3}>
                  <FormControl>
                    <Text mb={3}>Limit</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} value={lEntry} p={'2px 10px'} onChange={(e) => setLEntry(e.target.value)} placeholder={'0.00'} />
                      <InputRightAddon children={`@Limit ${symbol ? symbol.sell : '...'}`} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <Box mb={4}>
                  <FormControl isInvalid={!isValidAmount('limit order')}>
                    <Flex justifyContent={'space-between'}>
                      <Text mb={3}>Amount</Text>
                      <Flex gap={2}>
                        <Text>Max: </Text>
                        {
                          activeSymbolBuyPrice && symbol24HrTicker ?
                            <Box textAlign={'right'} color={(isValidAmount('limit order') ? 'green.400' : 'red.400')}>
                              <Text fontSize={'12px'}>{(maxBuyPrice || 0).toFixed(4)} {symbol ? symbol.buy : '...'}</Text>
                              <Text fontSize={'10px'}>{currencyFormat(getCurrentBalance())}</Text>
                            </Box> :
                            <Spinner size={'md'} />
                        }
                      </Flex>
                    </Flex>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} value={lAmount} onChange={(e) => setLAmount(e.target.value)} placeholder={'0.00'} />
                      <InputRightAddon children={symbol ? symbol.buy : '...'} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                    <FormErrorMessage>
                      <Text fontSize={'smaller'} >
                        {
                          symbol && activeSymbolBuyPrice ?
                            <>Insufficient balance. {lAmount} {symbol.buy} = {currencyFormat(activeSymbolBuyPrice * lAmount)}</> :
                            ''
                        }
                      </Text>
                    </FormErrorMessage>
                    {activeSymbolBuyPrice && isValidAmount('limit order') && lAmount !== '' ? <Text fontSize={'12px'} color={'yellow.500'}>USD: -{currencyFormat(lAmount * parseFloat(activeSymbolBuyPrice))}</Text> : ''}
                  </FormControl>
                </Box>

                <Box mb={3}>
                  <FormControl>
                    <Text mb={3}>Take profit T/P</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} value={lTp} onChange={(e) => setLTp(e.target.value)} p={'2px 10px'} placeholder={'0.00'} />
                      <InputRightAddon children={`@${symbol ? symbol.sell : '...'}`} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <Box mb={3}>
                  <FormControl>
                    <Text mb={3}>Stop loss S/L</Text>
                    <InputGroup>
                      <Input variant={'unstyled'} type='number' bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} value={lSl} onChange={(e) => setLSl(e.target.value)} placeholder={'0.00'} />
                      <InputRightAddon children={`@${symbol ? symbol.sell : '...'}`} bgColor={'rgba(255,255,255,0.1)'} border={'none'} />
                    </InputGroup>
                  </FormControl>
                </Box>

                <hr style={{ borderColor: 'rgba(255,255,255,0.3)' }} />

                <Box mt={3} fontSize={'14px'} mb={4}>

                  <Flex justifyContent={'space-between'} mb={1}>
                    <Flex>
                      <Text>Total</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>({symbol ? symbol.sell : '...'})</Text>
                    </Flex>
                    <Text>{getCurrentTotal('limit order') <= 0 ? '0.0000000000' : `${getCurrentTotal('limit order')}`}</Text>
                  </Flex>

                  <Flex justifyContent={'space-between'} mb={1} fontSize={'xs'}>
                    <Flex>
                      <Text>Commission</Text>
                      <Text>&nbsp;</Text>
                      <Text color={'rgba(255,255,255,0.5)'}>(0.30%)</Text>
                    </Flex>
                    <Text>{getCurrentTotal('limit order') <= 0 ? '0.0000000000' : `${(getCurrentTotal('limit order') * kCommissionRate).toFixed(5)}`}</Text>
                  </Flex>

                </Box>

                <SimpleGrid columns={2} gap={2} mb={3}>
                  <Button colorScheme={'green'} onClick={() => handleLTrade('buy')} isDisabled={isLDisabled()}>Buy</Button>
                  <Button colorScheme={'red'} onClick={() => handleLTrade('sell')} isDisabled={isLDisabled()}>Sell</Button>
                </SimpleGrid>
              </Box>
          }
        </Box>
      </CardBody>
    </Card>
  )
}

export default TradeCard