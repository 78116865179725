import { useRef, useState } from 'react'
import { Box, Container, Heading, Flex, Square, Image, Text, Center, Spinner, SimpleGrid, Card, CardBody, Icon, Button, Input, Select, Link } from '@chakra-ui/react'
// import signin from '../../assets/images/signin.png'
// import { FaExclamationCircle } from 'react-icons/fa'
import { QRCodeSVG } from 'qrcode.react';
import * as api from '../../services/api_service'
import { toast } from 'react-toastify';
import { kBgColor, toastConfig } from '../../utils/global';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { currencyFormat, isStringEmpty } from '../../utils/helper';
import UseAuth from '../../hooks/UseAuth';
import { BsCashCoin, BsShieldFillCheck, BsFillCloudCheckFill } from 'react-icons/bs';
import { AiOutlineLeftCircle } from 'react-icons/ai'
import bubbleBg from '../../assets/images/bubble.png'
import profile2 from '../../assets/images/letsgo.png'
import uploadicon from '../../assets/images/uploadicon.png'
import coinsImg from '../../assets/images/coins.png'
import { MdContentCopy } from 'react-icons/md'
import { BiEdit } from 'react-icons/bi'
import NewAddressButton from '../../components/NewAddressButton';
import ManageAddressButton from '../../components/ManageAddressButton';

function Wallet() {

  const { setUser, user, liveTradingBalance, demoTradingBalance } = UseAuth();

  const tTypeSearch = useLocation().search;
  const tTypeSearchName = new URLSearchParams(tTypeSearch).get('to') || 'overview';
  const tTypeName = tTypeSearchName.toLowerCase();
  const [tab, setTab] = useState(tTypeName);

  const [isFetching, setIsFetching] = useState(true);
  const [depositPageIndex, setDepositPageIndex] = useState(0);
  const [withdrawalPageIndex, setWithdrawalPageIndex] = useState(0);
  const [isDepositing, setIsDepositing] = useState(false)
  const [isWithdrawing, setIsWithdrawing] = useState(false)

  // const [deposits, setDeposits] = useState([]);
  // const [withdrawals, setWithdrawals] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [paymentAddresses, setPaymentAddresses] = useState([]);
  const [amount, setAmount] = useState('');
  const [network, setNetwork] = useState('');
  const [wAmount, setWAmount] = useState('');
  const [wWallet, setWWallet] = useState('');

  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const proof = useRef()
  const copyText = useRef()

  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [depositPageIndex]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [withdrawalPageIndex]);

  async function getWalletData() {
    setIsFetching(true)
    try {
      const resp = await Promise.all([
        // api.deposits(),
        // api.withdrawals(),
        api.addresses(),
        api.paymentAddresses(),
      ])
      console.log(resp)
      // setDeposits(resp[0].data.data)
      // setWithdrawals(resp[1].data.data)
      setAddresses(resp[0].data.data)
      setPaymentAddresses(resp[1].data.data)
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false)
      console.log(error.response.data)
      toast.error("Error fetching wallet data", toastConfig)
    }
  }

  useEffect(() => {
    getWalletData();
  }, [])

  const changeHandler = (e) => {
    const file_ = e.target.files[0];
    if (file_) {
      if (!file_.type.match(imageMimeType)) {
        alert("Image mime type is not valid");
        return;
      }
      setFile(file_);
    } else {
      setFile(null)
    }
  }
  useEffect(() => {
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result)
          // console.log(result)
        }
      }
      fileReader.readAsDataURL(file);
    } else {
      setFileDataURL(null)
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [file]);

  function copyToClipboard(e) {
    console.log(copyText.current.innerText);
    navigator.clipboard.writeText(copyText.current.innerText)
    toast.success("Copied to clipboard", { autoClose: 1000 })
  };

  async function handleDeposit() {
    // console.log('file', proof.current.files[0])
    // console.log('amount', amount)
    setIsDepositing(true)
    try {
      await api.deposit({ amount: amount, account: network, address: resolvePaymentAddress(network)?.address, image: file })
      // setDeposits((dep) => [...dep, resp.data.data])
      proof.current.value = ''
      setFile(null)
      setFileDataURL(null)
      setIsDepositing(false)
      setDepositPageIndex(2)
      toast.success("Deposit Request Sent", { ...toastConfig, autoClose: 8000 })
    } catch (error) {
      console.log(error)
      setFile(null)
      setFileDataURL(null)
      setIsDepositing(false)
      toast.error(error.response.data.message, toastConfig)
    }
  }

  async function handleWithdraw() {
    setIsWithdrawing(true)
    try {
      const resp = await api.withdraw('wallet', { wallet: wWallet, amount: wAmount })
      // setWithdrawals((with_) => [...with_, resp.data.data])
      setIsWithdrawing(false)
      // type === 'referral' ? setUser((user_) => ({ ...user_, referral_balance: (user_.referral_balance - resp.data.data.amount) })) : setUser((user_) => ({ ...user_, balance: (user_.balance - resp.data.data.amount) }))
      setUser((user_) => ({ ...user_, balance: (user_.balance - resp.data.data.amount) }))
      setWithdrawalPageIndex(1)
      toast.success("Withdrawal Request Sent", toastConfig)
    } catch (error) {
      console.log(error)
      setIsWithdrawing(false)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 5000 })
    }
  }

  function handleDepositPayInitial() {
    if (isStringEmpty(amount) || isStringEmpty(network)) {
      // alert(network)
      toast.error("Atleast one field was left undefined. Kindly fill in the amount and network correctly", { ...toastConfig, autoClose: 5000 });
    } else {
      // if (user.plan_request === 1) {
      //   toast.error('You requested a trading plan. Please wait while your plan request is being confirmed. You can contact support to quicken confirmation');
      // } else if (user.plan_data == null) {
      //   toast.error('Request a trading plan to continue.');
      // } else {
      //   setDepositPageIndex(1);
      // }
      setDepositPageIndex(1);
    }

    // if (isStringEmpty(amount) || isStringEmpty(network)) {
    //   // alert(network)
    //   toast.error("Atleast one field was left undefined. Kindly fill in the amount and network correctly", { ...toastConfig, autoClose: 5000 });
    // } else {
    //   if (user.plan_request === 1) {
    //     toast.error('You requested a trading plan. Please wait while your plan request is being confirmed. You can contact support to quicken confirmation');
    //   } else if (user.plan_data == null) {
    //     toast.error('Request a trading plan to continue.');
    //   } else if (Number(amount) < Number(user.plan_data.min)) {
    //     toast.error(`Deposit amount must be greater than minimum deposit. Minimum deposit: ${currencyFormat(user.plan_data.min)}`);
    //   } else if (Number(amount) > Number(user.plan_data.max)) {
    //     toast.error(`Deposit amount must not exceed your current plan maximum deposit.  Maximum deposit: ${currencyFormat(user.plan_data.min)}`);
    //   } else {
    //     setDepositPageIndex(1);
    //   }

    // }
  }

  function resolvePaymentAddress(name) {
    if (paymentAddresses.length > 0) {
      let filteredAddress = paymentAddresses.filter((e) => e.name === name)
      if (filteredAddress.length > 0) {
        return filteredAddress[0]
      }
      return null
    }
    return null
  }

  function resolveWithdrawalAddress(id) {
    if (addresses.length > 0) {
      let filteredAddress = addresses.filter((e) => e.id === parseInt(id))
      if (filteredAddress.length > 0) {
        return filteredAddress[0]
      }
      return null
    }
    return null
  }

  function handleResetBalance() {
    toast.success("Request sent successfully", { autoClose: 1000 })
  }

  return (
    <>
      <Container maxW={'container.lg'}>
        <Box h={'auto'} mt={{ base: 5, md: 10 }}>
          <Flex justifyContent={'space-between'} mb={'20px'}>
            <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>Wallet</Heading>
            <Flex>
              <Box mr={5}>
                <Box borderBottom={tab === 'overview' ? 'solid 2px #fff' : 'none'} color={tab === 'overview' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('overview')} fontSize={14}>Overview</Box>
              </Box>
              <Box mr={5}>
                <Box borderBottom={tab === 'deposit' ? 'solid 2px #fff' : 'none'} color={tab === 'deposit' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('deposit')} fontSize={14}>Deposit</Box>
              </Box>
              <Box mr={2}>
                <Box borderBottom={tab === 'withdraw' ? 'solid 2px #fff' : 'none'} color={tab === 'withdraw' ? '#fff' : 'rgba(240,240,240,0.6)'} pb={2} cursor={'pointer'} onClick={() => setTab('withdraw')} fontSize={14}>Withdraw</Box>
              </Box>
            </Flex>
          </Flex>

          {
            !isFetching ?
              tab === 'overview' ?
                <Box>
                  <SimpleGrid columns={{ base: 1, md: 3 }} gap={5} mb={10}>
                    <Card bgColor={'rgba(40,94,97, 0.4)'} color={'whiteAlpha.800'} bgImage={bubbleBg} bgSize={'cover'}>
                      <CardBody>
                        <Box p={5}>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <Text fontSize={'smaller'}>Main Balance</Text>
                              <Text fontSize={'3xl'}>{currencyFormat(user.balance + liveTradingBalance)}</Text>
                              <Flex gap={3}>
                                <Button onClick={() => setTab('deposit')} variant={'link'}><Text fontSize={'smaller'} textDecor={'underline'}>Top up</Text></Button>
                                <Button onClick={() => setTab('withdraw')} variant={'link'}><Text fontSize={'smaller'} textDecor={'underline'}>Withdraw</Text></Button>
                              </Flex>
                            </Box>
                            <Center>
                              <Icon as={BsCashCoin} w={'45px'} h={'45px'} />
                            </Center>
                          </Flex>
                        </Box>
                      </CardBody>
                    </Card>

                    <Card bgColor={'rgba(85,60,154,0.4)'} color={'whiteAlpha.800'} bgImage={bubbleBg} bgSize={'cover'}>
                      <CardBody>
                        <Box p={5}>
                          <Flex justifyContent={'space-between'}>
                            <Box>
                              <Text fontSize={'smaller'}>Demo Balance</Text>
                              <Text fontSize={'3xl'}>{currencyFormat(user.demo_balance + demoTradingBalance)}</Text>
                              <Button onClick={() => handleResetBalance()} variant={'link'}><Text fontSize={'smaller'} textDecor={'underline'}>Reset balance</Text></Button>
                            </Box>
                            <Center>
                              <Icon as={BsCashCoin} w={'45px'} h={'45px'} />
                            </Center>
                          </Flex>
                        </Box>
                      </CardBody>
                    </Card>
                  </SimpleGrid>

                  <SimpleGrid columns={{ base: 1, md: 2 }} gap={5}>
                    <Card bgColor={kBgColor} p={5} color={'whiteAlpha.700'}>
                      <CardBody>
                        <Text fontSize={'2xl'} mb={3} mt={{ base: '-30px', md: 10 }}>Deposit and trade in multiple crypto currencies. Fast and secured</Text>
                        <Text mb={10}>Choose from various crypto currencies <Button onClick={() => setTab('deposit')} variant={'link'} textDecor={'underline'}>here</Button></Text>
                        <Text>
                          We build a custom portfolio based on your preferred risk profile.
                          As prices move, our engine adjusts your portfolio to ensure it remains balanced and in line with your risk profile.
                        </Text>
                      </CardBody>
                    </Card>
                    <Image src={profile2} w={'100%'} />
                  </SimpleGrid>

                  <Box>
                    <Link as={RouterLink} to={'/user/wallet/transactions'} fontSize={'md'} color={'blue.400'}>See Transaction History</Link>
                  </Box>
                </Box>
                :

                // for deposit
                tab === 'deposit' ?

                  <Center>
                    {<Box w={{ base: '100%', md: '500px' }}>
                      <Card shadow={'dark-lg'} bgColor={kBgColor} color={'whiteAlpha.800'} mb={'100px'}>
                        <CardBody>
                          {
                            depositPageIndex === 1 ?

                              //deposit upload page
                              <Box textAlign={'left'} p={5}>
                                <Flex justifyContent={'space-between'} mb={8}>
                                  <Button onClick={() => { setDepositPageIndex(0) }} variant={'link'}> <Icon as={AiOutlineLeftCircle} width={'28px'} height={'28px'} /> </Button>
                                  <Spinner size={'md'} />
                                  <Text></Text>
                                </Flex>

                                <Text mb={1} color={'whiteAlpha.600'} fontSize={'small'}>Amount in USD</Text>
                                <Text fontSize={'25px'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} mb={5} display={'inline-block'} pr={2}>{currencyFormat(amount)}</Text>
                                <Icon ml={3} as={BiEdit} cursor={'pointer'} onClick={(e) => setDepositPageIndex(0)} />
                                {/* QRCodeSVG */}

                                <Text mb={1} color={'whiteAlpha.600'} fontSize={'small'}>Pay to</Text>
                                <Box>
                                  <Box fontSize={'18px'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} mb={5} pr={2}>
                                    <span ref={copyText}>{resolvePaymentAddress(network).address}</span>
                                    <Icon ml={3} as={MdContentCopy} cursor={'pointer'} onClick={(e) => copyToClipboard(e)} />
                                  </Box>
                                </Box>

                                <Text mb={1} color={'whiteAlpha.600'} fontSize={'small'}>Scan QR code</Text>
                                <Box>
                                  <Box bgColor={"#fff"} display={"inline-block"} borderBottom={'solid 1px rgba(200,200,200,0.3)'} mb={5} p={3}>
                                    <QRCodeSVG value={resolvePaymentAddress(network)?.address} />
                                  </Box>
                                </Box>

                                <Text mb={1} color={'whiteAlpha.600'} fontSize={'small'}>Network</Text>
                                <Text fontSize={'19px'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} mb={5} display={'inline-block'} pr={2}>{resolvePaymentAddress(network)?.name}</Text>

                                <Text mb={3} color={'whiteAlpha.600'} fontSize={'small'}>Upload payment proof</Text>
                                <Input type="file" accept='.png, .jpg, .jpeg' onChange={changeHandler} style={{ display: 'none' }} ref={proof} />
                                <Flex gap={4} cursor={'pointer'} onClick={() => proof.current.click()}>
                                  <Image
                                    boxSize='70px'
                                    objectFit='cover'
                                    src={!fileDataURL ? uploadicon : fileDataURL}
                                    alt='Dan Abramov' />
                                  <Center border={'dashed 1px rgba(200,200,200,0.2)'} flex={1}>
                                    {!fileDataURL ? <Text>Select file</Text> : <Flex gap={2}><Text>Edit file</Text><Icon as={BiEdit} h={'20px'} w={'20px'} /></Flex>}
                                  </Center>
                                </Flex>

                                <Box h={7} />

                                <Text fontSize={'smaller'} mb={8}>
                                  <Text color={'whiteAlpha.600'}>Secured by</Text>
                                  <Text><Icon color={'blue.400'} as={BsShieldFillCheck} /> {process.env.REACT_APP_NAME} pay</Text>
                                </Text>

                                <Button colorScheme='cyan' w={'180px'} size={'sm'} onClick={() => handleDeposit()} isDisabled={isDepositing}>Pay</Button>
                              </Box> :

                              //deposit success page
                              depositPageIndex === 2 ?
                                <Box textAlign={'center'} p={5}>
                                  <Center minH={'300px'} w={'100%'} pb={10}>
                                    <Box textAlign={'center'}>

                                      <Icon as={BsFillCloudCheckFill} w={'23px'} h={'23px'} color={'green.500'} mb={2} />
                                      <Text mb={2}>Deposit request of {currencyFormat(amount)} in {resolvePaymentAddress(network)?.abbreviation} has been sent. Pending confirmation </Text>
                                      <Box mb={10}>
                                        <Link as={RouterLink} textDecor={'underline'} color={'blue.500'} fontSize={13} to={'/user/wallet/transactions?type=deposits'}>Transaction history</Link>
                                      </Box>

                                      <Center mb={3}><Image src={coinsImg} w={'100px'} /></Center>

                                    </Box>
                                  </Center>

                                  <Text fontSize={'smaller'} mb={8}>
                                    <Text color={'whiteAlpha.600'}>Secured by</Text>
                                    <Text><Icon color={'blue.400'} as={BsShieldFillCheck} /> {process.env.REACT_APP_NAME} pay</Text>
                                  </Text>
                                </Box> :

                                //deposit main page
                                <Box textAlign={'center'} p={5}>
                                  <Flex justifyContent={'space-between'}>
                                    <Text mb={8}>Deposit</Text>
                                    <Link as={RouterLink} to={'/user/wallet/transactions?type=deposits'} color={'blue.500'} textDecor={'underline'} fontSize={13}>Deposit History</Link>
                                  </Flex>

                                  <Text mb={2} color={'whiteAlpha.600'} fontSize={'small'}>Amount (USD)</Text>
                                  <Input mb={8} w={'120px'} placeholder='0.00' type='number' value={amount} onChange={(e) => setAmount(e.target.value)} textAlign={'center'} fontSize={'30px'} variant={'unstyled'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} pb={3} />

                                  <Text mb={3} color={'whiteAlpha.600'} fontSize={'small'}>Network</Text>
                                  <Center mb={10}>
                                    <Select placeholder={'Select'} type='number' textAlign={'center'} value={network} onChange={(e) => setNetwork(e.target.value)} fontSize={'25px'} variant={'unstyled'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} pb={3} w={'200px'}>
                                      {
                                        paymentAddresses.length > 0 &&
                                        paymentAddresses.map((e, k) => (
                                          <option value={e.name} key={k} style={{ color: '#000', fontSize: '15px' }}>{e.name}</option>
                                        ))
                                      }
                                    </Select>
                                  </Center>

                                  <Text fontSize={'smaller'} mb={8}>
                                    <Text color={'whiteAlpha.600'}>Secured by</Text>
                                    <Text><Icon color={'blue.400'} as={BsShieldFillCheck} /> {process.env.REACT_APP_NAME} pay</Text>
                                  </Text>

                                  <Button colorScheme='cyan' w={'180px'} size={'sm'} onClick={() => handleDepositPayInitial()}>Pay</Button>
                                </Box>
                          }
                        </CardBody>
                      </Card>

                      <Box>
                        <Text fontSize={'2xl'} mb={5}>Faqs</Text>

                        <Box mb={10}>
                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} mb={5}>How can i top up my {process.env.REACT_APP_NAME} wallet balance ?</Text>

                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} borderLeft={'solid 1px rgba(200,200,200,0.3)'} pl={5}>
                            The deposit process is quick and hassle free. Deposit can be made using cryptocurrencies like Bitcoin, Ethereum, BNB, Litecoin, USDT, etc.
                          </Text>
                        </Box>

                        <Box mb={10}>
                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} mb={5}>Can I withdraw my money anytime ?</Text>

                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} borderLeft={'solid 1px rgba(200,200,200,0.3)'} pl={5}>you have full ownership of your portfolio.
                            So, you can withdraw money anytime you want, straight to external wallet.
                            We will give you unlimited support, in case you need any help in doing so.</Text>
                        </Box>

                        <Box h={'50px'}></Box>
                      </Box>
                    </Box>}
                  </Center>

                  :

                  //for withdrawal
                  <Center>
                    {<Box w={{ base: '100%', md: '500px' }}>
                      <Card shadow={'dark-lg'} bgColor={kBgColor} color={'whiteAlpha.800'} mb={'100px'}>
                        <CardBody>
                          {

                            //withdrawal success page
                            withdrawalPageIndex === 1 ?
                              <Box textAlign={'center'} p={5}>
                                <Center minH={'300px'} w={'100%'} pb={10}>
                                  <Box textAlign={'center'}>

                                    <Icon as={BsFillCloudCheckFill} w={'23px'} h={'23px'} color={'green.500'} mb={2} />
                                    <Text mb={2}>Withdrawal request of {currencyFormat(wAmount)} in {resolveWithdrawalAddress(wWallet)?.account} to {resolveWithdrawalAddress(wWallet)?.address} has been sent. Pending confirmation </Text>
                                    <Box mb={10}>
                                      <Link as={RouterLink} textDecor={'underline'} color={'blue.500'} fontSize={13} to={'/user/wallet/transactions?type=withdrawals'}>Transaction history</Link>
                                    </Box>

                                    <Center mb={3}><Image src={coinsImg} w={'100px'} /></Center>

                                  </Box>
                                </Center>

                                <Text fontSize={'smaller'} mb={8}>
                                  <Text color={'whiteAlpha.600'}>Secured by</Text>
                                  <Text><Icon color={'blue.400'} as={BsShieldFillCheck} /> {process.env.REACT_APP_NAME} pay</Text>
                                </Text>
                              </Box> :

                              //withdrawal main page
                              <Box textAlign={'center'} p={5}>
                                <Flex justifyContent={'space-between'}>
                                  <Text mb={8}>Withdraw</Text>
                                  <Link as={RouterLink} to={'/user/wallet/transactions?type=withdrawals'} color={'blue.500'} textDecor={'underline'} fontSize={13}>Withdrawal History</Link>
                                </Flex>

                                <Text mb={2} color={'whiteAlpha.600'} fontSize={'small'}>Amount (USD)</Text>
                                <Input w={'120px'} placeholder='0.00' type='number' value={wAmount} onChange={(e) => setWAmount(e.target.value)} textAlign={'center'} fontSize={'30px'} variant={'unstyled'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} pb={3} />
                                <Box mb={8} mt={1} fontSize={13}>
                                  {
                                    user.demo ?
                                      <Text color={'yellow.500'}>Switch to live account</Text> :
                                      <Text color={'green.500'}>Available: {currencyFormat(user.balance)}</Text>
                                  }
                                </Box>

                                <Text color={'whiteAlpha.600'} fontSize={'small'} mb={2}>Select address</Text>
                                <Center>
                                  <Flex gap={4} mb={3}>

                                    <NewAddressButton paymentAddresses={paymentAddresses} setAddresses={setAddresses} />
                                    <ManageAddressButton addresses={addresses} setAddresses={setAddresses} />
                                  </Flex>
                                </Center>
                                <Center mb={10}>
                                  <Select placeholder={'Select'} type='number' textAlign={'center'} value={wWallet} onChange={(e) => setWWallet(e.target.value)} fontSize={'20px'} variant={'unstyled'} borderBottom={'solid 1px rgba(200,200,200,0.3)'} w={'200px'}>
                                    {
                                      addresses.length > 0 &&
                                      addresses.map((e, k) => (
                                        <option key={k} value={e.id} style={{ color: '#000', fontSize: '12px' }}>{e.account} {e.address}</option>
                                      ))
                                    }
                                  </Select>
                                </Center>

                                <Text fontSize={'smaller'} mb={8}>
                                  <Text color={'whiteAlpha.600'}>Secured by</Text>
                                  <Text><Icon color={'blue.400'} as={BsShieldFillCheck} /> {process.env.REACT_APP_NAME} pay</Text>
                                </Text>

                                <Button colorScheme='cyan' w={'180px'} size={'sm'} onClick={() => handleWithdraw()} isDisabled={isWithdrawing}>Submit</Button>
                              </Box>
                          }
                        </CardBody>
                      </Card>

                      <Box>
                        <Text fontSize={'2xl'} mb={5}>Faqs</Text>

                        <Box mb={10}>
                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} mb={5}>How can i top up my {process.env.REACT_APP_NAME} wallet balance ?</Text>

                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} borderLeft={'solid 1px rgba(200,200,200,0.3)'} pl={5}>
                            The deposit process is quick and hassle free. Deposit can be made using cryptocurrencies like Bitcoin, Ethereum, BNB, Litecoin, USDT, etc.
                          </Text>
                        </Box>

                        <Box mb={10}>
                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} mb={5}>Can I withdraw my money anytime ?</Text>

                          <Text fontSize={{ base: 'sm', md: 'md' }} color={'whiteAlpha.700'} borderLeft={'solid 1px rgba(200,200,200,0.3)'} pl={5}>you have full ownership of your portfolio.
                            So, you can withdraw money anytime you want, straight to external wallet.
                            We will give you unlimited support, in case you need any help in doing so.</Text>
                        </Box>

                        <Box h={'50px'}></Box>
                      </Box>
                    </Box>}
                  </Center>


              : // fetching
              <Square h={'200px'} mt={{ base: '-20px', md: '100px' }}>
                <Box>
                  <Center>
                    <Spinner size={'lg'} />
                  </Center><br />
                  {/* <Image
                    src={signin}
                    pos={'fixed'}
                    left={{ base: '20px', md: 'calc(50% - 500px/2)' }}
                    w={{ base: '400px', md: '500px' }} /> */}
                </Box>
              </Square>
          }
        </Box>
      </Container>
    </>
  )
}

export default Wallet