import { Box, Card, CardBody, Text } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import UseAuth from '../hooks/UseAuth';
import { currencyFormat, ucfirst } from '../utils/helper';
import { useMemo } from 'react';

function TraderOrders() {

    const { orders, user } = UseAuth();

    const _orders = useMemo(() => {
        if (user.demo === 0) {
            return orders.filter((e) => e.status === 1 && e.type === 'live')
        }else {
            return orders.filter((e) => e.status === 1 && e.type === 'demo')
        }
    }, [user, orders]);

    return (
        <Box mt={1}>
            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} m={2} shadow={'dark-lg'}>
                <CardBody>
                    <Box h={'240px'} overflow={'auto'}>
                        <Text mb={5} borderBottom={'solid 1px rgba(250,250,250, 0.4)'} pb={2}> Opened Orders ({_orders.length})</Text>
                        {
                            orders.length > 0 ?
                                <TableContainer>
                                    <Table size={'sm'}>
                                        <TableCaption> Your opened positions will appear here. </TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Time</Th>
                                                <Th>Pair</Th>
                                                <Th>Action</Th>
                                                <Th>Amount</Th>
                                                <Th>Entry</Th>
                                                <Th>Type</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody color={'whiteAlpha.700'}>
                                            {
                                                _orders.map((order) => (
                                                    <Tr>
                                                        <Td>{order.created_at}</Td>
                                                        <Td>{order.symbol.name}</Td>
                                                        <Td>{ucfirst(order.direction)}</Td>
                                                        <Td>{currencyFormat(order.amount)}</Td>
                                                        <Td>{order.entry} {order.symbol.sell}</Td>
                                                        <Td>{ucfirst(order.type)}</Td>
                                                    </Tr>
                                                ))
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer> :
                                <Box fontSize={'13px'}>Your opened positions will appear here.</Box>
                        }
                    </Box>
                </CardBody>
            </Card>
        </Box>
    )
}

export default TraderOrders