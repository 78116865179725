import { Box, Card, CardBody, Input, Text, Icon, Button } from '@chakra-ui/react'
import {
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { FaStar } from 'react-icons/fa'
import { isStringEmpty } from '../utils/helper'

function Watchlist({ watchlist, symbols, toggleWatchlist, symbol24HrTicker }) {

    const [searchInput, setSearchInput] = useState('')

    const filteredWatchlist = useMemo((e) => {
        if (!isStringEmpty(searchInput)) {
            const _filtered = watchlist.filter((e) => `${e.symbol.name}`.toLowerCase().search(searchInput.toLowerCase()) + 1 > 0);
            // console.log(_filtered)
            return _filtered;
        }
        return watchlist;
    }, [searchInput, watchlist])

    return (
        <Box>
            <Card bgColor={'rgb(19, 23, 34)'} color={'whiteAlpha.800'} shadow={'dark-lg'}>
                <CardBody>
                    <Box h={'400px'} overflow={'auto'}>
                        <Text>Watchlist</Text>

                        <Box mt={3}>
                            <Input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} variant={'flushed'} bgColor={'rgba(255,255,255,0.1)'} p={'2px 10px'} placeholder={symbol24HrTicker !== null ? symbol24HrTicker.symbol : ''} />

                            <Box mt={3}>
                                {
                                    watchlist.length > 0 ?

                                        //has records section
                                        <Box fontSize={'13px'}>
                                            {/* <Text>Saved pairs will appear here.</Text> */}

                                            <Box mt={5}>
                                                <Text borderBottom={'solid 1px #fff'} display={'inline-block'} mb={3}>Saved Pairs</Text>

                                                <TableContainer>
                                                    <Table variant='unstyled' size={'sm'}>
                                                        <Tbody>
                                                            {
                                                                filteredWatchlist.map((e, k) => (
                                                                    <Tr key={k} _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                        <Td cursor={'pointer'} onClick={() => window.location.href = '/user/trader?symbol='+`${e.symbol.name}`.toLowerCase()} textAlign={'left'} fontSize={'12px'}>{e.symbol.name}</Td>
                                                                        <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => toggleWatchlist(e.symbol.id)} variant={'link'} color={'orange.400'}><Icon as={FaStar} /></Button></Td>
                                                                    </Tr>
                                                                ))
                                                            }
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box> :

                                        //no record section
                                        <Box fontSize={'13px'}>
                                            <Text>Saved pairs will appear here.</Text>

                                            <Box mt={5}>
                                                <Text borderBottom={'solid 1px #fff'} display={'inline-block'} mb={3}>Popular</Text>

                                                <TableContainer>
                                                    <Table variant='unstyled' size={'sm'}>
                                                        <Tbody>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>{symbols[0]?.name}</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[0] && toggleWatchlist(symbols[0].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>{symbols[1]?.name}</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[1] && toggleWatchlist(symbols[1].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>{symbols[2]?.name}</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[2] && toggleWatchlist(symbols[2].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>{symbols[3]?.name}</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[3] && toggleWatchlist(symbols[3].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                                                            </Tr>
                                                            <Tr _hover={{ bgColor: 'rgba(240,240,240,0.1)' }} borderBottom={'solid 1px rgba(230,230,230,0.15)'}>
                                                                <Td textAlign={'left'} fontSize={'12px'}>{symbols[4]?.name}</Td>
                                                                <Td textAlign={'right'} fontSize={'12px'}><Button onClick={() => symbols[4] && toggleWatchlist(symbols[4].id)} variant={'link'}><Icon as={FaStar} /></Button></Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                </CardBody>
            </Card>
        </Box>
    )
}

export default Watchlist