import { Box, Card, CardBody, Container, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FcLineChart, FcBusinessman, FcServices, FcCurrencyExchange, FcUndo, FcCableRelease } from "react-icons/fc";
import { kBgColor } from '../../utils/global';
import UseAuth from '../../hooks/UseAuth';

function More() {

    const navigate = useNavigate();
    const {logout} = UseAuth();

    return (
        <Container maxW={'container.lg'} pb={5}>
            <Box h={'auto'} mt={{ base: 5, md: 10 }} mb={10}>
                <Flex justifyContent={'space-between'} mb={'30px'}>
                    <Heading size={'lg'} display={'inline-block'} pb={2} mb={2}>More</Heading>
                </Flex>

                <Box>
                    
                    <Card onClick={() => navigate('/user/plans')} mb={5} w={{base: '100%', md: '400px'}} shadow={'dark-lg'} cursor={'pointer'} bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Flex gap={3}>
                                <Icon as={FcLineChart} w={8} h={8} />
                                <Text fontWeight={'bold'} mt={1}>Trading Plans</Text>
                            </Flex>
                        </CardBody>
                    </Card>

                    <Card onClick={() => navigate('/user/account?to=profile')} mb={5} w={{base: '100%', md: '400px'}} shadow={'dark-lg'} cursor={'pointer'} bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Flex gap={3}>
                                <Icon as={FcBusinessman} w={8} h={8} />
                                <Text fontWeight={'bold'} mt={1}>My Account</Text>
                            </Flex>
                        </CardBody>
                    </Card>

                    <Card onClick={() => navigate('/user/account?to=settings')} mb={5} w={{base: '100%', md: '400px'}} shadow={'dark-lg'} cursor={'pointer'} bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Flex gap={3}>
                                <Icon as={FcServices} w={8} h={8} />
                                <Text fontWeight={'bold'} mt={1}>Preference</Text>
                            </Flex>
                        </CardBody>
                    </Card>
                    
                    <Card onClick={() => navigate('/user/referral')} mb={5} w={{base: '100%', md: '400px'}} shadow={'dark-lg'} cursor={'pointer'} bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Flex gap={3}>
                                <Icon as={FcCurrencyExchange} w={8} h={8} />
                                <Text fontWeight={'bold'} mt={1}>Referral</Text>
                            </Flex>
                        </CardBody>
                    </Card>
                    
                    <Card onClick={() => navigate('/user/account?to=apps')} mb={5} w={{base: '100%', md: '400px'}} shadow={'dark-lg'} cursor={'pointer'} bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Flex gap={3}>
                                <Icon as={FcCableRelease} w={8} h={8} />
                                <Text fontWeight={'bold'} mt={1}>Connected Apps</Text>
                            </Flex>
                        </CardBody>
                    </Card>
                    
                    <Card onClick={() => logout()} mb={5} w={{base: '100%', md: '400px'}} shadow={'dark-lg'} cursor={'pointer'} bgColor={kBgColor} color={'whiteAlpha.800'}>
                        <CardBody>
                            <Flex gap={3}>
                                <Icon as={FcUndo} w={8} h={8} />
                                <Text fontWeight={'bold'} mt={1}>Logout</Text>
                            </Flex>
                        </CardBody>
                    </Card>
                </Box>
            </Box>

            <Box h={'50px'} />
        </Container>
    )
}

export default More